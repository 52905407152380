<template>
  <v-layout class="custom-phone-input">
    <v-flex class="country-code">
      <v-select
        dense
        filled
        placeholder="Country"
        solo
        flat
        v-model="countryCode"
        :items="countryList"
        hide-details
        color="cyan"
        :disabled="disabled"
        :loading="loading"
        item-text="name"
        item-value="iso2"
        item-color="cyan"
        return-object
        v-on:change="updateActiveCountry"
      >
        <template v-slot:selection>
          <div
            :class="activeCountry.iso2.toLowerCase()"
            class="vti__flag mr-2"
          />
        </template>
        <template v-slot:item="data">
          <span :class="data.item.iso2.toLowerCase()" class="vti__flag mr-2" />
          <span>{{ data.item.name }} {{ `+${data.item.dialCode}` }}</span>
        </template>
      </v-select>
    </v-flex>
    <v-flex class="width-100">
      <v-text-field
        :key="phoneKey"
        v-mask="phoneMask"
        v-model.trim="phone"
        :prefix="phonePrefix"
        :rules="rules"
        dense
        filled
        :disabled="disabled"
        :loading="loading || numberLoading"
        :placeholder="label"
        solo
        flat
        color="cyan"
        :hide-details="hideDetails"
        v-on:keyup="validateNumber(phone)"
        v-on:change="
          (e) => {
            $emit('change', e);
          }
        "
        v-on:blur="(e) => $emit('blur', e)"
        v-on:focus="(e) => $emit('focus', e)"
      >
        <template #append>
          <v-icon v-if="numberLoading" class="mdi-spin" color="cyan"
            >mdi-loading</v-icon
          >
        </template>
      </v-text-field>
      <template v-if="invalidNumber">
        <span style="color: red; font-weight: 600">{{ invalidNumber }}</span>
      </template>
    </v-flex>
  </v-layout>
</template>
<style src="@/assets/sass/sprite.scss" lang="scss"></style>
<script>
import ValidationMixin from "@/core/plugins/validation-mixin";
import allCountries from "@/core/plugins/country-list";
import LocalService from "@/core/services/local.service";
import { QUERY } from "@/core/services/store/request.module";
export default {
  mixins: [ValidationMixin],
  model: {
    prop: "value",
    event: "input",
  },
  props: {
    value: {
      type: String,
      default: null,
    },
    id: {
      type: String,
      default: "phone",
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "Phone Number",
    },
    phoneKey: {
      type: Number,
      default: 1,
    },
  },
  watch: {
    value(param1, param2) {
      if (param1 != param2) {
        this.setValues();
      }
    },
    phone() {
      this.emitPhoneNumber();
    },
  },
  data() {
    return {
      phone: null,
      validateNumberCheck: null,
      invalidNumber: null,
      numberLoading: false,
      isValidNo: false,
      phonePrefix: "+65",
      countryCode: {
        areaCodes: null,
        dialCode: "65",
        iso2: "SG",
        name: "Singapore",
        priority: 0,
        phone_character_limit: 8,
      },
      activeCountry: {
        areaCodes: null,
        dialCode: "65",
        iso2: "SG",
        name: "Singapore",
        priority: 0,
        phone_character_limit: 8,
      },
      phoneMask: null,
      countryList: [],
      allCountries: allCountries,
      timeout: null,
    };
  },
  methods: {
    validateNumber(number) {
      this.$nextTick(() => {
        if (number && number.length) {
          let maxLimit = Number(number.length);
          if (maxLimit < 8) {
            return false;
          }
          if (this.countryCode.dialCode == "91" && maxLimit < 10) {
            return false;
          }
          clearTimeout(this.timeout);

          this.timeout = setTimeout(() => {
            if (number) {
              const _this = this;
              this.numberLoading = true;
              let apiKey = process.env.VUE_APP_MOBILE_KEY;
              const mobile_number = `${this.phonePrefix}-${number}`;
              _this.$store
                .dispatch(QUERY, {
                  url: "https://micro.businessthrust.com/api/check-mobile",
                  data: {
                    mobile: mobile_number,
                    apikey: apiKey,
                  },
                })
                .then(({ data }) => {
                  this.validateNumberCheck = data.result;
                  if (
                    this.validateNumberCheck &&
                    !this.validateNumberCheck.valid &&
                    this.validateNumberCheck.line_type == "N/A"
                  ) {
                    // (this.phone = null),
                    this.invalidNumber = "Invalid Number";
                  } else {
                    this.invalidNumber = null;
                  }
                })
                .catch((error) => {
                  _this.logError(error);
                })
                .finally(() => {
                  this.numberLoading = false;
                });
            }
          }, 500);
        }
      });
    },
    validateNumberMala(number) {
      if (number) {
        const _this = this;
        let apiKey = process.env.VUE_APP_MOBILE_KEY;
        this.numberLoading = true;
        const mobile_number = `${this.phonePrefix}-${number}`;
        _this.$store
          .dispatch(QUERY, {
            url: "https://micro.businessthrust.com/api/check-mobile",
            data: {
              mobile: mobile_number,
              apikey: apiKey,
            },
          })
          .then(({ data }) => {
            this.validateNumberCheck = data.result;
            if (
              this.validateNumberCheck &&
              !this.validateNumberCheck.valid &&
              this.validateNumberCheck.line_type == "N/A"
            ) {
              this.phone = null;
              this.invalidNumber = "Invalid Number";
            } else {
              this.invalidNumber = null;
            }
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            this.numberLoading = false;
          });
      }
    },

    getActiveCountries() {
      this.countryList = LocalService.getData("phone_countries");
      this.countryCode = this.lodash.find(this.countryList, {
        dialCode: "65",
      });
      if (!this.countryCode) {
        this.countryCode = this.countryList[0];
      }
    },
    setValues() {
      const value = this.lodash.replace(this.value, "+", "");
      const phoneCode = this.lodash.split(value, "-", 2);
      if (phoneCode && phoneCode[0]) {
        const countryCode = this.lodash.find(this.countryList, {
          dialCode: phoneCode[0],
        });
        if (countryCode) {
          this.countryCode = countryCode;
          this.activeCountry = countryCode;
          const { dialCode, phone_character_limit } = this.countryCode;
          this.phonePrefix = `+${dialCode}`;
          if (dialCode) {
            this.$nextTick(() => {
              var str = new Array(phone_character_limit + 1).join("#");
              this.phoneMask = `${str}`;
            });
          }
        }
      }
      if (phoneCode.length == 2) {
        this.phone = phoneCode[1];
      }
      // this.phone = this.value ? this.value : null;
      // this.$nextTick(() => {
      //   this.phone = this.value ? this.value : null;
      // });
    },
    updateActiveCountry() {
      const { dialCode, phone_character_limit } = this.countryCode;
      if (dialCode) {
        var str = new Array(phone_character_limit + 1).join("#");
        this.phoneMask = `${str}`;
        this.phonePrefix = `+${dialCode}`;
      }
      /* this.phone = null; */
      this.activeCountry = this.countryCode;
      this.$nextTick(() => {
        this.validateNumber(this.phone);
        /* this.phone = null; */
      });
      this.invalidNumber = null;
    },
    emitPhoneNumber() {
      let _phone = "";
      if (this.phone) {
        _phone = `${this.phonePrefix}-${this.phone}`;
      }
      this.$emit("input", _phone);
    },
  },
  computed: {
    rules() {
      const { dialCode } = this.countryCode;
      /*   let maxLimitss =
        dialCode.length + 2 + this.activeCountry.phone_character_limit; */

      let maxLimit = 14; /* dialCode.length + 2 +  14*/
      let minLimit = 8; /* dialCode.length + 2 +  8*/
      if (dialCode == "91") {
        minLimit = 10;
      }
      const rule = [
        this.validateRules.minLength(this.phone, "number", minLimit),
        this.validateRules.maxLength(this.phone, "number", maxLimit),
      ];
      if (this.numberLoading) {
        rule.push(
          () => !this.numberLoading || `Please wait for phone no validation`
        );
      }
      if (this.required) {
        rule.push(this.validateRules.required(this.phone, "number"));
      }
      if (this.invalidNumber) {
        rule.push(
          this.validateRules.invalidNumber(this.invalidNumber, this.label)
        );
      }
      return rule;
    },
  },
  mounted() {
    this.getActiveCountries();

    // this.updateActiveCountry();
    this.setValues();
  },
};
</script>
