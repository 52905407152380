<template>
  <Dialog :common-dialog="productDialog" :dialog-width="dialog_width">
    <template v-slot:title>
      <v-layout>
        <v-flex>
          <span class="text-capitalize font-size-20"
            >Add SKILLS , LICENCE & CERTS</span
          >
        </v-flex>
      </v-layout>
    </template>
    <template v-slot:body>
      <div>
        <div
          v-if="skillCertItemAlpha && skillCertItemAlpha.length"
          class="d-flex mb-2"
          style="overflow-x: scroll"
        ></div>
        <div
          v-if="skillCertItemCategory && skillCertItemCategory.length"
          class="d-flex mb-2"
          style="overflow-x: scroll"
        >
          <div
            style="position: relative"
            v-for="(cat, index) in skillCertItemCategory"
            :key="`category-${index}`"
            class="custom-border-right"
          >
            <v-btn
              color="cyan white--text custom-bold-button"
              depressed
              tile
              :disabled="pageLoading"
              :text="category == cat.value"
              v-on:click="filter_cat(cat.value)"
            >
              {{ cat.label }}
            </v-btn>
          </div>
        </div>
        <div class="d-flex mb-2">
          <div class="d-flex">
            <v-text-field
              v-model="search"
              class="line-item-header-text px-0 v-slot-m-none"
              placeholder="Search..."
              dense
              filled
              solo
              :disabled="pageLoading"
              clearable
              v-on:click:clear="clearFilter('search')"
              flat
              color="cyan"
              style="width: 300px"
            />
            <v-btn
              style="height: 32px !important"
              class="custom-bold-button ml-2"
              depressed
              :disabled="pageLoading"
              v-on:click="getCarts()"
              color="cyan white--text"
              ><v-icon>mdi-magnify</v-icon></v-btn
            >
            <v-btn
              style="height: 32px !important"
              class="custom-bold-button ml-2"
              depressed
              :disabled="pageLoading"
              v-on:click="getCarts()"
              color="cyan white--text"
              ><v-icon>mdi-reload</v-icon></v-btn
            >
          </div>
          <v-spacer></v-spacer>
          <div class="text-end pl-3">
            <v-autocomplete
              dense
              filled
              color="cyan"
              item-color="cyan"
              :items="categoryList"
              placeholder="Category"
              solo
              flat
              item-text="text"
              item-value="text"
              class="width-100"
              clearable
              v-on:click:clear="clearFilter('category')"
              v-on:change="getCarts"
              v-model.trim="category"
            >
            </v-autocomplete>
          </div>
        </div>
        <div>
          <div class="d-flex grey lighten-4">
            <div
              class="font-weight-600 py-2 text-center d-flex justify-center"
              style="width: 5%"
            >
              <v-checkbox
                v-on:change="update_all_line_item($event)"
                hide-details
                :disabled="pageLoading"
                class="my-0 py-0 px-0 mx-0 dense-checkbox"
                color="cyan"
              />
            </div>
            <div
              class="color-custom-blue py-2 font-weight-700 text-left text-capitalize"
              style="width: 15%"
            >
              Color
            </div>
            <div
              class="color-custom-blue py-2 font-weight-700"
              style="width: 40%"
            >
              Name
            </div>
            <div
              class="color-custom-blue py-2 font-weight-700"
              style="width: 40%"
            >
              Type
            </div>
          </div>
          <div style="overflow-y: scroll; max-height: calc(100vh - 360px)">
            <!-- <div v-if="pageLoading" class="my-4 py-4">
              <v-progress-linear
                color="cyan"
                indeterminate
                rounded
                height="6"
              ></v-progress-linear>
            </div> -->
            <!-- skillCertItem && skillCertItem.length -->
            <div v-if="skillCertItem && skillCertItem.length > 0">
              <div
                v-for="(row, index) in skillCertItem"
                :key="index"
                class="d-flex mb-0 mx-1 alternate-listing-row-div"
                style="border-bottom: 1px solid #ddd7d7"
              >
                <div
                  class="font-weight-600 text-center d-flex justify-center"
                  style="width: 5%"
                >
                  <v-checkbox
                    v-model="row.status"
                    hide-details
                    class="my-0 py-0 px-0 mx-0 dense-checkbox"
                    color="cyan"
                  />
                </div>
                <div style="width: 15%" class="">
                  <v-chip small :color="row.color" variant="flat"></v-chip>
                </div>
                <div class="text-left" style="width: 40%">
                  <div class="text-capitalize">
                    {{ row.name }}
                  </div>
                </div>
                <div class="ml-4" style="width: 40%">
                  <div class="text-capitalize">
                    {{ row.type }}
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <p
                class="m-0 row-not-found text-center font-weight-500 font-size-16"
              >
                <img
                  :src="$assetURL('media/error/empty.png')"
                  class="row-not-found-image"
                />
                Uhh... There are no item at the moment.
              </p>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:action>
      <v-flex class="text-right">
        <v-btn
          style="height: 32px !important"
          class="custom-bold-button mr-2"
          depressed
          :disabled="pageLoading"
          color="cyan white--text"
          v-on:click="save_Cert_item()"
          >Save</v-btn
        >
        <v-btn
          style="height: 32px !important"
          class="custom-bold-button"
          depressed
          :disabled="pageLoading"
          v-on:click="$emit('close', true)"
          >Close</v-btn
        >
      </v-flex>
    </template>
  </Dialog>
</template>
<script>
// import { mapGetters } from "vuex";
import { ErrorEventBus } from "@/core/lib/message.lib";
import Dialog from "@/view/pages/partials/Dialog.vue";
import { QUERY } from "../../core/services/store/request.module";

export default {
  name: "Add-skill",
  props: {
    productType: {
      type: String,
      default: null,
    },
    productDialog: {
      type: Boolean,
      default: false,
    },
    moduleType: {
      type: Number,
      default: 0,
    },
    relatedId: {
      type: Number,
      default: 0,
    },
    isType: {
      type: Number,
      default: 0,
    },
    updatedData: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      skillCertItemAlpha: null,
      pageLoading: false,
      search: null,
      category: null,
      categoryList: [
        { text: "Skill" },
        { text: "Certificate" },
        { text: "License" },
      ],
      skillCertItem: [],
      skillCertItemCategory: [],
    };
  },
  watch: {
    productDialog(param) {
      if (param) {
        this.getCarts();
      }
    },
  },
  components: {
    Dialog,
  },
  methods: {
    update_all_line_item(status) {
      for (let i = 0; i < this.skillCertItem.length; i++) {
        this.skillCertItem[i].status = status;
      }
    },

    save_Cert_item() {
      let selected_carts = [];

      let status = false;
      for (let item of this.skillCertItem) {
        if (item.status) {
          status = true;
          selected_carts.push(item);
        }
      }

      if (!status) {
        ErrorEventBus.$emit("update:error", `Please select Skills.`);
        return false;
      }
      this.$emit("update-values", selected_carts);
      this.$emit("close", true);
    },
    getCarts() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(QUERY, {
          url: "all-carts",
          data: {
            type: this.category,
            search: this.search,
          },
        })
        .then(({ data }) => {
          this.skillCertItem = data;
          if (this.updatedData && this.updatedData.length > 0) {
            for (let i = 0; i < this.updatedData.length; i++) {
              for (let j = 0; j < this.skillCertItem.length; j++) {
                if (
                  this.updatedData[i].id === this.skillCertItem[j].id &&
                  this.updatedData[i].type === this.skillCertItem[j].type
                ) {
                  this.skillCertItem[j].status = true;
                  break; // Assuming each id is unique, exit inner loop once match is found
                }
              }
            }
          }
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    clearFilter(type) {
      if (type == "search") {
        this.search = null;
      }
      if (type == "category") {
        this.category = null;
      }

      this.getCarts();
    },
  },
  mounted() {},
  computed: {
    dialog_width() {
      return this.lodash.toSafeInteger((document.body.clientWidth / 100) * 50);
    },
  },
};
</script>
