<template>
  <Dialog :commonDialog="documentDialog">
    <template v-slot:title> Upload Document</template>
    <template v-slot:body>
      <v-form
        ref="documentForm"
        v-model.trim="export_valid"
        lazy-validation
        v-on:submit.stop.prevent="documentValidated()"
      >
        <table class="table-document" width="100%">
          <template v-for="(file, index) in files">
            <tr
              :key="`file-row-${index}`"
              :class="{ 'table-alternate-row': index % 2 }"
            >
              <td colspan="2" class="px-2">
                <div class="mb-3">
                  <label for="file" class="font-weight-600 required">
                    Upload File
                  </label>
                  <v-file-input
                    hide-details
                    class="px-0"
                    dense
                    filled
                    solo
                    flat
                    v-model="file.file"
                    placeholder="Select File"
                    prepend-icon=""
                    prepend-inner-icon="$file"
                    v-on:change="updateFile(index, $event)"
                    v-on:click:clear="updateFile(index, $event)"
                    color="cyan"
                  />
                </div>
                <div class="mb-1">
                  <label for="name" class="font-weight-600 required">
                    File Name</label
                  >
                  <TextField
                    id="name"
                    dense
                    filled
                    placeholder="File Name"
                    solo
                    flat
                    counter="50"
                    v-model="file.name"
                    color="cyan"
                    :maxlength="250"
                    :rules="[validateRules.required(file.name, 'Name')]"
                  />
                </div>
              </td>
              <td
                v-if="false"
                width="10%"
                align="center"
                valign="middle"
                class="px-2"
                :rowspan="extensive ? 4 : 1"
              >
                <v-btn
                  v-on:click="removeFile(index)"
                  color="red lighten-1 white--text"
                  class=""
                  icon
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </td>
            </tr>
            <tr
              v-if="!extensive"
              :key="`file-date-${index}`"
              :class="{ 'table-alternate-row': index % 2 }"
            >
              <td class="px-2">
                <div>
                  <label for="file" class="font-weight-600"> Start Date</label>
                  <DatePicker
                    solo
                    :nullable="true"
                    placeholder="Start Date"
                    id="export-from-date"
                    v-model="file.start_date"
                    :min-date="currentDate"
                  ></DatePicker>
                </div>
              </td>
              <td class="px-2">
                <div>
                  <label for="file" class="font-weight-600"> End Date</label>
                  <DatePicker
                    solo
                    :nullable="true"
                    placeholder="End Date"
                    :pageLoading="pageLoading"
                    v-model="file.end_date"
                    :min-date="file.start_date"
                  ></DatePicker>
                </div>
              </td>
            </tr>
            <tr
              v-if="!extensive"
              :key="`file-reminder-date-${index}`"
              :class="{ 'table-alternate-row': index % 2 }"
            >
              <td colspan="2" class="px-2">
                <div>
                  <label for="file" class="font-weight-600">
                    Reminder Date</label
                  >
                  <DatePicker
                    solo
                    placeholder="Reminder Date"
                    id="export-from-date"
                    :nullable="true"
                    v-model="file.reminder_date"
                  ></DatePicker>
                </div>
              </td>
            </tr>
            <tr
              v-if="!extensive"
              :key="`file-tag-${index}`"
              :class="{ 'table-alternate-row': index % 2 }"
            >
              <td colspan="2" class="px-2">
                <div>
                  <label for="file" class="font-weight-600"> Tags </label>
                  <TagAutoComplete
                    type="text"
                    v-model="file.tags"
                    :document-tag-type="documentType"
                    :tag-type="tagType"
                  />
                </div>
              </td>
            </tr>
          </template>
        </table>
        <!-- <v-btn
          color="cyan white--text"
          class="mt-2 ml-4"
          tile
          depressed
          v-on:click="addMore()"
        >
          <v-icon>mdi-plus</v-icon> Add More...
        </v-btn> -->
      </v-form>
      <div class="px-4">
        <AttachFile
          v-if="false"
          @update:file="addMoreFile"
          :validFileTypes="fileTypes"
          :max-limit="maxLimit"
          :total-files="files.length"
        ></AttachFile>
        <v-form
          v-if="false"
          ref="documentForm"
          v-model.trim="export_valid"
          lazy-validation
          v-on:submit.stop.prevent="documentValidated()"
        >
          <FileUpload v-model="files" :maxLimit="maxLimit"> </FileUpload>
        </v-form>
      </div>
    </template>
    <template v-slot:action>
      <v-btn
        depressed
        tile
        :disabled="attachLoading"
        :loading="attachLoading"
        v-on:click="$emit('close', true)"
      >
        Close
      </v-btn>
      <v-btn
        :disabled="!export_valid || attachLoading"
        :loading="attachLoading"
        class="white--text"
        depressed
        color="cyan"
        tile
        v-on:click="documentValidated()"
      >
        Submit
      </v-btn>
    </template>
  </Dialog>
</template>
<script>
import { mapGetters } from "vuex";
import Dialog from "@/view/pages/partials/Dialog.vue";
import ApiService from "@/core/services/api.service";
import DatePicker from "@/view/pages/partials/Datepicker.vue";
import MomentJS from "moment-timezone";
import ValidationMixin from "@/core/plugins/validation-mixin";
import FileUpload from "@/view/components/app-component/FileInput.vue";
import AttachFile from "@/view/components/AttachFile";
//import { SET_ERROR } from "@/core/services/store/common.module";
import TagAutoComplete from "@/view/pages/partials/TagAutoCompleteInput";
export default {
  mixins: [ValidationMixin],
  name: "export-dialog",
  model: {
    prop: "value",
    event: "change",
  },
  data() {
    return {
      fileIds: [],
      export_valid: true,
      attachLoading: false,
      /*  prefile:[], */
      files: [
        {
          file: null,
          name: null,
          remark: null,
          suffix: null,
          tags: [],
          start_date: null,
          end_date: null,
          reminder_date: null,
        },
      ],
      customer: null,
      currentDate: MomentJS().format("YYYY-MM-DD"),
      search: null,
      items: [],
      pageLoading: false,
    };
  },
  props: {
    tagType: {
      type: String,
      default: null,
    },
    moduleType: {
      type: String,
      default: null,
    },
    documentDialog: {
      type: Boolean,
      default: false,
    },
    isUpdate: {
      type: Boolean,
      default: false,
    },
    documentType: {
      type: Number,
      default: null,
    },
    index: {
      type: Number,
      default: null,
    },
    documentId: {
      type: Number,
      default: null,
    },
    parentType: {
      type: String,
      default: null,
    },
    parentTypeId: {
      type: Number,
      default: null,
    },
    maxLimit: {
      type: Number,
      default: 5,
    },
    fileTypes: {
      type: Array,
      default: () => {
        return [
          "jpeg",
          "jpg",
          "png",
          "gif",
          "pdf",
          "docx",
          "doc",
          "xls",
          "xlsx",
        ];
      },
    },
    extensive: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    documentDialog(param) {
      this.files = [];
      if (param) {
        this.customer = null;
        this.files = [
          {
            file: null,
            name: null,
            remark: null,
            tags: [],
            suffix: null,
            base64: null,
            start_date: null,
            end_date: null,
            reminder_date: null,
          },
        ];
      }
    },
  },
  methods: {
    manageLimit(e, index) {
      if (this.files[index].remark && this.files[index].remark.length > 199) {
        e.preventDefault();
      }
    },
    onPaste(e, index) {
      let coppied = e.clipboardData.getData("Text");
      let preVal = this.files[index].remark;
      let finalval = String(preVal) + String(coppied);
      if (Number(finalval.length) > 199) {
        let trimValue = finalval.substring(0, 200);
        this.files[index].remark = trimValue;
        e.preventDefault();
      }
    },

    addMore() {
      this.files.push({
        file: null,
        name: null,
        remark: null,
        suffix: null,
      });
    },

    documentValidated() {
      if (!this.$refs.documentForm.validate()) {
        return false;
      }
      if (!this.$refs.documentForm.validate()) {
        return false;
      }

      if (this.isUpdate) {
        this.submitDocumentOnlocal();
      } else {
        this.submitDocument();
      }
    },
    async submitDocumentOnlocal() {
      if (this.files?.length > 0) {
        for (let i = 0; i < this.files.length; i++) {
          const base64 = await this.convert_base_64(this.files[i].file);
          this.files[i].base64 = base64;
        }
      }
      this.$emit("success", this.files);
    },
    convert_base_64(file) {
      return new Promise((resolve, reject) => {
        try {
          const reader = new FileReader();
          reader.addEventListener(
            "load",
            function () {
              resolve(reader.result);
            },
            false
          );
          reader.readAsDataURL(file);
        } catch (error) {
          reject(error);
        }
      });
    },
    submitDocument() {
      const _this = this;
      const params = new FormData();
      for (let i = 0; i < this.files.length; i++) {
        if (this.files[i].file && this.files[i].name) {
          params.append(`file[${i}][file]`, this.files[i].file);
          params.append(`file[${i}][name]`, this.files[i].name);
          if (this.files[i].remark) {
            params.append(`file[${i}][remark]`, this.files[i].remark);
          }

          if (this.files[i].start_date) {
            params.append(`file[${i}][start_date]`, this.files[i].start_date);
          }
          if (this.files[i].end_date) {
            params.append(`file[${i}][end_date]`, this.files[i].end_date);
          }
          if (this.files[i].reminder_date) {
            params.append(
              `file[${i}][reminder_date]`,
              this.files[i].reminder_date
            );
          }
          if (this.files[i]?.tags) {
            for (let j = 0; j < this.files[i]?.tags?.length; j++) {
              params.append(
                `file[${i}][tags][${j}][text]`,
                this.lodash.toString(this.files[i].tags[j].text)
              );
              params.append(
                `file[${i}][tags][${j}][color]`,
                this.lodash.toString(this.files[i].tags[j].color)
              );
            }
          }
        }
      }

      params.append("type_id", this.documentId);
      params.append("type", this.documentType);
      params.append("module_type", this.moduleType);

      if (this.parentType && this.parentTypeId && this.parentTypeId > 0) {
        params.append("parent_type", this.parentType);
        params.append("parent_type_id", this.parentTypeId);
      }

      this.attachLoading = true;

      ApiService.setHeader();
      ApiService.upload("all-documents", params)
        .then((data) => {
          if (data) {
            this.fileIds = data.data.data;
            _this.$emit("getUploadedIds", this.fileIds);
          }
          this.$emit("success", true);
          this.$emit("close", true);
        })
        .catch((error) => {
          this.$emit("error", error);
          this.logError(error);
        })
        .finally(() => {
          this.attachLoading = false;
        });
    },
    updateFile(index, file) {
      if (file && file.name) {
        this.files[index].name = file.name.split(".").slice(0, -1).join(".");
        this.files[index].suffix = `${file.name.split(".").pop()}`;
      } else {
        this.files[index].name = null;
        this.files[index].suffix = null;
      }
    },
    removeFile(index) {
      this.files.splice(index, 1);
    },
    getTags() {
      const _this = this;
      ApiService.setHeader();
      ApiService.get("setting/all-tags").then((res) => {
        _this.items = res.data && res.data.data ? res.data.data : [];
      });
    },
    filterTag(item, queryText, itemText) {
      if (item.header) return false;
      const hasValue = (val) => (val != null ? val : "");

      const text = hasValue(itemText);
      const query = hasValue(queryText);

      return (
        text.toString().toLowerCase().indexOf(query.toString().toLowerCase()) >
        -1
      );
    },
  },
  components: {
    // SET_ERROR,
    Dialog,
    DatePicker,
    AttachFile,
    FileUpload,
    TagAutoComplete,
  },
  mounted() {
    this.getTags();
  },
  computed: {
    ...mapGetters(["errors", "localDB"]),
  },
};
</script>
<style scoped>
.removeShadow > .v-input__control > .v-input__slot {
  box-shadow: none !important;
}
.v-autocomplete:not(.v-input--is-focused).v-select--chips input {
  padding: 0;
}
.v-application .py-3 {
  padding-bottom: 0px !important;
}
.text-right.col-md-12.col {
  margin: -10px;
}
</style>
