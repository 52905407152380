<template>
  <v-menu
    ref="datepicker"
    v-model="datepicker"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :id="id"
        :label="label"
        :loading="loading"
        :disabled="disabled"
        :rules="rules"
        readonly
        flat
        solo
        dense
        class=""
        :class="className"
        :placeholder="btxPlaceholder"
        hide-details
        :clearable="clearable"
        :prepend-inner-icon="hidePrefixIcon ? '' : 'mdi-calendar'"
        v-bind="attrs"
        v-on="on"
        :value="formattedDate"
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="date"
      no-title
      :min="minDate"
      :max="maxDate"
      :first-day-of-week="weekday"
      :disabled="disabled"
      :readonly="readonly"
      @input="datepicker = false"
    ></v-date-picker>
  </v-menu>
</template>

<script>
import moment from "moment-timezone";
/* import AppConfiguration from "@/core/config/app.config"; */
import { GET } from "@/core/services/store/request.module";
moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  name: "date-picker",
  model: {
    prop: "value",
    event: "input",
  },
  props: {
    value: {
      type: [Date, String],
      default: null,
    },
    hidePrefixIcon: {
      type: Boolean,
      default: false,
    },
    minDate: {
      type: [Date, String],
      default: null,
    },
    maxDate: {
      type: [Date, String],
      default: null,
    },
    rules: {
      type: [Array, Object],
      default: () => {
        return [];
      },
    },
    placeholder: {
      type: String,
      default: "Date",
    },
    id: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    contentClass: {
      type: Object,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    className: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      date: null,
      datepicker: false,
      /*  dateFormatted: null, */
      AppConfiguration: {},
      timeFormat: null,
      dateTimeFormat: null,
      dateFormat: null,
    };
  },
  watch: {
    minDate() {
      this.date = null;
    },
    maxDate() {
      this.date = null;
    },
    date() {
      this.emitValue();
    },
    value() {
      this.date = this.value;
    },
  },
  methods: {
    getAppConfig() {
      const _this = this;
      _this.$store
        .dispatch(GET, { url: "configuration" })
        .then(({ data }) => {
          (this.AppConfiguration = data),
            (this.dateFormat = data.date_format),
            (this.dateTimeFormat = data.date_format + " " + data.time_format),
            (this.timeFormat = data.time_format);
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    emitValue() {
      /* this.dateFormatted = this.formatDate(this.date); */
      this.$emit("input", this.date);
      this.$emit("change", this.date);
    },
    formatDate(date) {
      if (!date) return null;
      return moment(date).format(this.dateFormat);
    },
    parseDate(date) {
      if (!date) return null;
      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
  },
  computed: {
    btxPlaceholder() {
      return this.placeholder + " [DD/MM/YYYY]";
    },
    formattedDate() {
      let Config = this.AppConfiguration;
      if (Config && this.date) {
        return moment(this.date).format(this.dateFormat);
      }
      return this.date;
    },
    weekday() {
      let Config = this.AppConfiguration;
      if (Config && Config.weekday == 1) {
        return 0;
      } else {
        return 1;
      }
    },
    /*  weekday() {
      let Config = AppConfiguration.get();
      if (Config && Config.weekday == 1) {
        return 0;
      } else {
        return 1;
      }
    }, */
  },
  mounted() {
    this.date = this.value;
    this.$nextTick(() => {
      this.emitValue();
    });
    this.getAppConfig();
  },
};
</script>
