<template>
  <v-card flat class="custom-grey-border remove-border-radius">
    <v-card-title class="headline grey lighten-4">
      <h3 class="font-weight-700 custom-headline color-custom-blue">
        Attachments
      </h3>
    </v-card-title>
    <v-card-text class="p-6 font-size-16">
      <template v-if="filesPrent[0]?.name">
        <v-simple-table
          class="attach-list"
          fixed-header
          min-height="67vh"
          width="100%"
        >
          <thead>
            <tr>
              <th width="50" class="simple-table-th">File</th>
              <th class="simple-table-th">Name</th>
              <th width="200" class="simple-table-th">Date</th>
              <!-- <th width="100" class="simple-table-th">Reminder</th> -->
              <th class="simple-table-th">Tags</th>
              <th width="50" class="simple-table-th text-end">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, index) in filesPrent" :key="index">
              <td>
                <div style="max-width: 50px; min-width: 50px; width: 50px">
                  <inline-svg
                    style="max-width: 40px; width: 40px"
                    :src="$assetURL(`media/mime/${row?.suffix}.svg`)"
                  />
                </div>
              </td>
              <td>{{ row?.name }}</td>
              <td>
                <div>
                  <b style="min-width: 64px" class="d-inline-block">Start</b
                  >:&nbsp;
                  <template v-if="row?.start_date">{{
                    formatDate(row?.start_date)
                  }}</template>
                  <em v-else class="text-muted"> no start</em>
                </div>
                <div>
                  <b style="min-width: 64px" class="d-inline-block">End</b
                  >:&nbsp;
                  <template v-if="row?.end_date">{{
                    formatDate(row?.end_date)
                  }}</template>
                  <em v-else class="text-muted"> no end</em>
                </div>
                <div>
                  <b style="min-width: 64px" class="d-inline-block">Reminder</b
                  >:&nbsp;
                  <template v-if="row?.reminder_date">{{
                    formatDate(row?.reminder_date)
                  }}</template>
                  <em v-else class="text-muted"> no reminder</em>
                </div>
              </td>
              <!-- <td> <template v-if="row?.reminder_date">{{ row?.reminder_date }}</template>
                                       <em v-else class="text-muted"> no reminder</em></td> -->
              <td width="20%">
                <template v-if="row.tags && row.tags?.length > 0">
                  <v-chip
                    small
                    v-for="(row, index) in row.tags"
                    :key="index"
                    class="mr-2 mb-1"
                    text-color="white"
                    :color="row.color"
                    >{{ row.text }}</v-chip
                  >
                </template>
                <em v-else class="text-muted"> no tags</em>
              </td>
              <td class="text-end">
                <v-btn
                  v-on:click="removeFile(index)"
                  color="red lighten-1 white--text"
                  class=""
                  icon
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </template>
      <div
        v-on:click="add_document_dialog = true"
        disabled
        class="py-5 mt-3 upload-area text-center text-secondary"
        :class="filesPrent?.length == 5 ? 'file-disabled' : ''"
      >
        <v-icon size="60">mdi-cloud-upload</v-icon>
        <div class="text-h5 text-secondary">Click here to select file</div>
        <p class="mt-1 text-grey-700">
          Allowed ( jpeg, jpg, png, gif, pdf, docx, doc, xls, xlsx ). <br />
          Max size of 5 MB <br />
          Max File Allowed ({{ filesPrent?.length }} / 5)
        </p>
      </div>
      <DocumentwithName
        :document-dialog="add_document_dialog"
        v-on:close="add_document_dialog = false"
        v-on:success="saveData"
        isUpdate
        :index="removeFileIndex"
        :document-type="documentType"
        :tag-type="tagType"
      >
      </DocumentwithName>
    </v-card-text>
  </v-card>
</template>
<script>
import DocumentwithName from "@/view/pages/DocumentwithName";
import ValidationMixin from "@/core/plugins/validation-mixin";
import CommonMixin from "@/core/plugins/common-mixin.js";

export default {
  name: "FileInput",
  mixins: [ValidationMixin, CommonMixin],
  data() {
    return {
      removeFileIndex: 0,
      attachLoading: false,
      refInputEl: null,
      add_document_dialog: false,
      activeClass: "",
      filesPrent: [],
      margeFile: [],
      timeout: null,
    };
  },
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    tagType: {
      type: String,
      default: null,
    },
    documentType: {
      type: Number,
      default: null,
    },
    isSingle: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [Array],
      default: () => {
        return [];
      },
    },
    maxLimit: {
      type: Number,
      default: 5,
    },
    maxSize: {
      type: Number,
      default: 5,
    },
    required: {
      type: Boolean,
      default: false,
    },
    totalFiles: {
      type: Number,
      default: 0,
    },
    validFileTypes: {
      type: Array,
      default: () => {
        return [
          "jpeg",
          "jpg",
          "png",
          "gif",
          "pdf",
          "docx",
          "doc",
          "xls",
          "xlsx",
        ];
      },
    },
  },
  watch: {
    value: {
      deep: true,
      handler(param) {
        const _file = this.lodash.cloneDeep(param);
        this.filesPrent = [..._file];
      },
    },
  },
  methods: {
    saveData(data) {
      this.margeFile = [...this.filesPrent, ...data];
      this.emitFile();
      this.add_document_dialog = false;
    },
    emitFile() {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.$emit("change", this.margeFile);
      }, 300);
    },
    removeFile(index) {
      this.margeFile.splice(index, 1);
      this.emitFile();
    },
  },
  components: {
    DocumentwithName,
  },
};
</script>
<style scoped>
.removeShadow > .v-input__control > .v-input__slot {
  box-shadow: none !important;
}

.v-autocomplete:not(.v-input--is-focused).v-select--chips input {
  padding: 0;
}

.v-application .py-3 {
  padding-bottom: 0px !important;
}

.text-right.col-md-12.col {
  margin: -10px;
}
</style>
